<template>
	<v-layout row wrap justify-center>
		<v-flex shrink font-italic>
			<span v-t="'client_workspace.task.history.created_at'" />
			<span shrink v-text="' '" />
			<span shrink class="font-weight-medium" v-text="creationDate" />
			<template v-if="creatorFullname">
				<span shrink v-text="' '" />
				<span v-t="'client_workspace.task.history.created_by'" shrink />
				<span shrink v-text="' '" />
				<span shrink class="font-weight-medium" v-text="creatorFullname" />
			</template>
			<span shrink v-text="'.'" />
			<template v-if="updateDate || editorFullname">
				<span shrink v-text="' '" />
				<template v-if="updateDate">
					<span v-t="'client_workspace.task.history.last_modified_at'" shrink />
					<span shrink v-text="' '" />
					<span class="font-weight-medium" shrink v-text="updateDate" />
					<template v-if="editorFullname">
						<span shrink v-text="' '" />
						<span v-t="'client_workspace.task.history.created_by'" shrink />
						<span shrink v-text="' '" />
						<span shrink class="font-weight-medium" v-text="editorFullname" />
					</template>
					<span shrink v-text="'.'" />
				</template>
			</template>
		</v-flex>
	</v-layout>
</template>

<script>
export default {
	name: 'TasksManagerTaskDetailsAuthors',
	props: {
		task: {
			required: true,
			type: Object
		}
	},
	computed: {
		creationDate: function () {
			return this.task.created_at ? this.$d(new Date(this.task.created_at.replace(/-/g, '/')), 'short') : this.$t('tasks.date_unknown')
		},
		creatorFullname: function () {
			return this.task.creator ? this.task.creator.firstname + ' ' + this.task.creator.lastname : this.$t('unknown')
		},
		updateDate: function () {
			return this.task.updated_at && this.task.created_at != this.task.updated_at ? this.$d(new Date(this.task.updated_at.replace(/-/g, '/')), 'short') : null
		},
		editorFullname: function () {
			return this.task.editor ? this.task.editor.firstname + ' ' + this.task.editor.lastname : this.$t('unknown')
		}
	}
}
</script>
